@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.employee-modal {
  width: 445px;
  height: 729px;

  .employee-form-loader-wrapper {
    height: 600px;
    display: flex;
    justify-content: center;
  }

  .employee-form {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: space-between;

    &__fields {
      margin-top: 24px;
    }

    &__flex-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 24px;
      min-height: 63px;
    }

    &__htbp-note {
      color: $purple;
      @include desktop-caption-medium-font;
    }

    &__htbp-note-button {
      display: inline;
      padding: 0;

      .button__content {
        color: $purple;
        text-decoration: underline;
      }
    }

    &__actions {
      margin-top: 46px;
      background-color: $white;
    }
  }
}
