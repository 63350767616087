.container {
  min-height: 120px;

  .title {
    padding-bottom: 2px;
  }

  [class^='loader'] {
    position: relative;
    top: 10px;
  }
}
