@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;

  .name {
    @include desktop-body-medium-font;
  }

  .btnsContainer {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    .btn {
      :global(.button__content) {
        display: flex;
        gap: 4px;
        align-items: center;
      }
    }

    .icon {
      width: 16px;
      height: 16px;
      margin-top: -2px;
    }
  }
}
