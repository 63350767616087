.archive-project-request-button {
  &__icon {
    width: 16px;
    height: 16px;
    margin-top: -2px;
  }

  .button__content {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
