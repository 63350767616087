@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  margin: 10px 0;

  .title {
    color: $color-front-light;
    @include desktop-caption-font;
  }

  .checkboxList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
  }
}
