@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  margin-top: 40px;
  max-width: 452px;
}

.header {
  @include desktop-title-medium-font;
}

.body {
  @include desktop-body-font;
  margin: 24px 0px;
}

.upload_container {
  display: flex;
  align-items: center;
  gap: 24px;
}

.button {
  background-color: $black-4;
  color: $black-38;
}

.link {
  text-decoration: underline;
  color: #006aff;
}
