@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  margin-bottom: 45px;

  .sectionTitle {
    font-size: 24px;
    margin-bottom: 33px;
    margin-top: 80px;
  }

  .table {
    min-height: unset;
  }
}

.notAssignedLabel {
  color: $color-disabled-front;
}

.modalLabelField {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;

  .modalLabel {
    color: $color-front-light;
    @include desktop-body-font;
  }

  .modalLabelValue {
    color: $color-front;
    @include desktop-body-medium-font;
  }
}

.inlineNotification {
  box-sizing: border-box !important;
  width: 100% !important;
}

.editDropdownBtn {
  color: $color-front;
  display: flex;
  align-items: center;

  svg {
    width: 24px;
  }

  svg path {
    fill: $color-front;
  }
}

.deleteDropdownBtn {
  color: $color-red-front;
  display: flex;
  align-items: center;

  svg path {
    fill: $color-red-front;
  }
}
