.Container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.CompanyData {
  display: flex;
  align-items: center;
  align-self: center;
  margin: 40px 0px;
}
.CompanyLogo {
  width: 150px;
  height: 50px;
}
.loader {
  display: flex;
  justify-content: center;
}
