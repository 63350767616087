@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

#accordion-item-content-advanced-settings {
  padding-top: 10px;
}

.project {
  padding: 32px 108px 108px 108px;

  .sz-divider {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .project-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__right {
      display: flex;
      gap: 16px;
    }

    .button {
      .button__content {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }

  .project-create {
    &__title {
      @include desktop-headline-medium-font;
      margin: 40px auto 27px;
      max-width: 600px;
      color: $color-front;
    }
  }

  .sz-tab__title {
    color: $color-front !important;
  }

  .project-edit {
    &__advanced-settings {
      max-width: 600px;
      margin: 24px auto;
      padding-left: 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .language-form {
        margin-top: 24px;
      }
    }
  }

  .project-form {
    max-width: 600px;
    margin: auto;

    &__title {
      @include desktop-title-font;
      margin-bottom: 24px;
    }

    &__fields-row {
      display: flex;
      gap: 24px;
    }

    &__module-type-select {
      max-width: 403px;
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 8px;
    }

    &__notification {
      width: 100%;
      margin-bottom: 16px;
    }
  }

  .project-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 600px;
    margin: 0px auto;

    &__title {
      @include desktop-title-medium-font;
      color: $color-front;
    }

    &__sub-title {
      @include desktop-title-font;
      color: $color-front;
    }

    &__right {
      display: flex;
      gap: 16px;
    }

    &__card {
      display: flex;
      gap: 4px;
    }

    &__card-label {
      color: $black-38;
      @include desktop-caption-font;
    }

    &__card-value {
      @include desktop-caption-font;
    }
  }

  .project-info {
    margin: 0px auto;
    padding: 24px 16px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 600px;

    &__details {
      display: flex;
      gap: 8px;
    }

    &__item {
      display: flex;
      gap: 8px;
    }

    &__title {
      @include desktop-title-font;
      color: $color-front;
      margin-top: 8px;
    }

    &__label {
      color: $color-front-light;
      flex-shrink: 0;
      @include desktop-body-font;
    }

    &__emphasis {
      @include desktop-body-medium-font;
    }

    &__warning {
      margin-bottom: 16px;
      width: 100%;
    }

    &__tooltip-content {
      color: $color-front;
      @include desktop-body-medium-font;
    }

    &__value {
      color: $color-front;
      @include desktop-body-medium-font;
      margin-right: 8px;
    }
  }

  .report-assignment,
  .project-company-signature {
    display: flex;
    gap: 24px;
    width: 100%;

    &__select {
      flex: 7;
    }

    .checkbox--container {
      flex: 3;
    }
  }

  .success-profile-overview-presentational {
    max-width: 600px;
    margin: auto;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;
    }

    &__header-title {
      @include desktop-title-medium-font;
    }

    &__subheader-title {
      @include desktop-title-font;
    }

    &__header-right {
      display: flex;
      gap: 8px;
    }

    &__job-roles {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 32px;
    }

    &__tabs-panel {
      margin-top: 24px;
    }
  }

  .success-profile-skills-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    &__text {
      @include desktop-title-medium-font;
      color: $black-16;
    }
  }

  .success-profile-skills-list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__item {
      display: flex;
      width: 60%;
      flex-direction: row;
      gap: 24px;
    }
  }

  .stage-actions {
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      margin-top: 24px;
    }

    &__title {
      @include desktop-title-medium-font;
    }

    &__add-action-button {
      .button__content {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }

  .stage-test-action-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;

    &__test-id-label {
      max-width: 50px;
    }

    &__type-label {
      max-width: 90px;

      .sz-text-input__input-element {
        cursor: pointer;
      }
    }

    &__question-numbers-input {
      max-width: 120px;
    }

    &__origin-select {
      max-width: 185px;
    }

    &__test-icon {
      min-width: 40px;
      height: 40px;
    }

    &__tp-project-id {
      max-width: 70px;
    }

    .sz-field-label__bottom-text:empty {
      display: none;
    }
  }

  .stage-role-skills-self-assessment-action {
    display: flex;
    gap: 16px;
    height: 43px;
    margin-bottom: 24px;

    &__bars-icon {
      min-width: 40px;
      height: 40px;
    }

    &__action-name-label {
      width: 140px;
      min-width: 140px;
    }

    &__edit-button-icon {
      width: 12px;
      height: 12px;
    }
    &__edit-button,
    .remove-skills-self-assessment-action-button {
      margin-top: 4px;
    }
  }
}

.stage-dei-action {
  display: flex;
  gap: 16px;
  height: 43px;

  &__dei-icon {
    min-width: 40px;
    height: 40px;
  }

  &__questions-preview {
    @include desktop-body-font;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    padding-top: 14px;
    color: $color-front;

    &__eye-icon {
      margin-left: auto;
      margin-right: -15px;
    }
  }

  &__action-name-label {
    width: 140px;
    min-width: 170px;
  }

  &__edit-button-icon {
    width: 12px;
    height: 12px;
  }

  &__edit-button,
  &__remove-button {
    margin-top: 4px;
  }
}

[class^='ReactModal__Content'] {
  &.remove-dei-action-modal,
  &.edit-dei-action-modal {
    width: 100% !important;
    max-width: 520px !important;
  }
}

.remove-dei-action-modal {
  @include desktop-body-font;
  color: $color-front;
}

.remove-dei-action-modal,
.edit-dei-action-modal {
  max-width: 520px;
}

.project-loader-wrapper {
  height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.invite-new-candidate-form {
  &__fields {
    gap: 16px;
    display: flex;
    flex-direction: column;
  }

  &__sso-notification {
    max-width: 100%;

    [class^='inline-notification-module_title'] {
      font-weight: normal !important;
    }
  }
}

.add-stage-action-modal {
  width: 400px;

  &__actions {
    display: flex;
    margin-top: 36px;
    justify-content: flex-end;
    gap: 8px;
  }
}

.edit-skills-self-assessment-action-modal {
  .sz-modal__header-container {
    margin-bottom: 24px;
  }
}

.skills-self-assessment-action-form {
  &__note {
    @include desktop-caption-font;
    color: $black-54;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }

  &__form-row {
    display: flex;
    justify-content: space-between;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }

  &__help-icon {
    cursor: pointer;
  }

  &__tooltip-note {
    text-align: center;
  }

  &__error-wrapper {
    @include desktop-caption-font;
    // TODO: Get color from theme
    color: #e54d42;
    height: 14px;
    margin-top: 36px;
  }

  &__title {
    @include desktop-body-medium-font;
  }
}
