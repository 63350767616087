@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.appbar {
  width: 100%;
  height: 80px;
  padding: 0 40px;
  box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &__left-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    gap: 40px;
  }

  &__logo {
    width: 125px;
    height: 24px;
    margin-bottom: 4px;
  }

  &__nav {
    display: flex;
    flex-direction: row;
    gap: 24px;
    height: 100%;
  }

  &__nav-item {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__nav-item-content {
    flex: 1;
    display: flex;
    align-items: center;
    @include desktop-body-medium-font;
  }

  &__nav-item_active {
    .appbar__nav-item-content {
      color: $blue;
    }

    .appbar__nav-item-underline {
      background-color: $blue;
    }
  }

  &__nav-item-underline {
    height: 4px;
  }
}
