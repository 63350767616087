.styled-confirmation-modal {
  .sz-modal-buttons {
    padding: 36px 0 0;
  }

  .sz-modal__header-text {
    margin: 0;
    height: auto;
  }

  .loader {
    min-height: 80px;
  }
}
