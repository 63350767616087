.project-request-company-logo {
  max-height: 24px;
  max-width: 160px;

  &__company-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
