@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  display: flex;
  align-items: center;
  gap: 24px;

  .typeSelectField {
    width: 150px;
  }

  .checkboxWrapper {
    width: 110px;
  }

  .label {
    @include desktop-body-medium-font;
    width: 250px;
  }
}
