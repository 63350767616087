@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: #fff;
}

* {
  font-family: 'Roboto';
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  font-stretch: normal;
  font-style: normal;
  padding: 0;
  margin: 0;
  border: none;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none;
}

button {
  cursor: pointer;
}

html {
  overflow-y: overlay;
  height: 100%;
}

body > iframe {
  pointer-events: none;
}

//Temporary fix, as the V5 of MUI Item doesn't have padding, and we are using it in the Grid
.MuiGrid-item {
  padding: 8px;
}

// Fix for the drawer overlay (we can put lower z-index if necessary
[class^='drawer-module_overlay'] {
  z-index: 1201 !important;
}

[class^='drawer-module_drawer'] {
  z-index: 1202 !important;
}
