@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.noSearchResultsContent {
  display: flex;
  gap: 10px;
  flex-direction: column;

  .noSearchResultsText {
    @include desktop-body-font;
  }
}
