.CompanyRoleCard {
  //   background-color: red;
  display: flex;
  align-items: center;
  width: 300px;
  justify-content: space-between;
}
.header {
  width: 300px;
  display: flex;
  justify-content: flex-end;
}
