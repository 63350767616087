// TODO: Refactor this components style.
.searchInputTechSkill {
  width: 40%;

  svg {
    path {
      fill: white !important;
    }
  }
}
