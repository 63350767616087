@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: $color-front;

  .title {
    margin-bottom: 24px;
    @include desktop-title-medium-font;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }
}
