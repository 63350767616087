.softSkillTable {
  :global {
    .cds--data-table {
      th:nth-child(1) {
        width: 70px;
      }
      th:nth-child(2) {
        width: 200px;
      }
    }
  }
}
