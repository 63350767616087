@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.project-request-manager-survey-modal {
  .project-request-manager-survey-form {
    &__fields {
      display: flex;
      flex-direction: column;
    }

    &__fields-array-error {
      @include desktop-caption-font;
      color: $purple;
      height: 16px;
    }

    .checkbox__input {
      min-width: 13px;
    }

    &__checkbox-label {
      @include desktop-body-medium-font;
    }

    &__checkbox-label-note {
      color: $black-38;
    }
  }
}
