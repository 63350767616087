@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';
.container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .description {
    @include desktop-caption-font;
    color: $color-front-light;
  }

  .formContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .formTitle {
    @include desktop-body-medium-font;
    margin-bottom: 4px;
  }

  .completedInlineNotificationWrapper {
    margin: -20px 0px 8px;
  }

  [class^='inline-notification-module'] {
    &.archivedInlineNotification,
    &.completedInlineNotification {
      width: 100% !important;
    }
    &.archivedInlineNotification {
      [class^='inline-notification-module_body'] {
        margin-top: 2px !important;
      }
    }
  }

  .completedForms {
    @include desktop-caption-medium-font;
    color: $color-front;
    margin-left: 3px;
  }

  .actionsContainer {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    width: 100%;
  }
}
