@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.content {
  @include desktop-caption-medium-font;
  display: inline-flex;
  align-items: center;
  gap: 4px;

  .icon {
    width: 16px;
    height: 16px;
  }
}
