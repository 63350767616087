.stage-indirect-invitation-modal {
  width: 460px;

  .copy-stage-indirect-invitation {
    display: flex;
    align-items: center;
    gap: 24px;
    margin: 24px 0;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }
}
