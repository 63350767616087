.table {
  :global {
    .cds--data-table {
      &-header {
        &__title {
          font-weight: 500;
        }
      }
    }
  }
}
