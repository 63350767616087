@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.create-project-request-user-modal {
  width: 672px;
  min-height: 339px;
  max-height: 654px;
  overflow: auto;

  .loader {
    height: 200px;
  }

  .create-project-request-contacts-form {
    margin-top: 36px;

    .button {
      .button__content {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }

    &__add-contact-button {
      margin-top: 16px;
    }

    &__add-icon {
      width: 16px;
      height: 16px;

      path {
        fill: $black;
      }
    }

    .create-project-request-contacts-form-row {
      display: flex;
      gap: 24px;
      align-items: center;

      &__role-select {
        max-width: 150px;
      }

      &__employee-select {
        max-width: 362px;
      }

      &__delete-button {
        &:hover {
          .project-request-create-form__delete-icon {
            width: 16px;
            height: 16px;

            path {
              fill: $green;
            }
          }
        }
      }

      &__delete-icon {
        width: 16px;
        height: 16px;

        path {
          fill: $black-54;
        }
      }
    }

    .sz-modal-buttons {
      margin-top: 36px;
    }
  }
}
