.formFieldsWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.projectSettingSection {
  margin-top: 24px;
}

.ssoInlineNotif {
  width: 100%;
  align-items: center;
}
