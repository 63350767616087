@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  display: flex;
  gap: 8px;
  align-items: center;

  .label {
    @include desktop-body-font;
    color: $color-front-light;
  }

  .value {
    @include desktop-body-medium-font;
    color: $color-front;
    margin-right: 8px;
  }
}
