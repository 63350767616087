@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.modal {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 8px $black-16;
  }

  .title {
    @include desktop-title-medium-font;
    margin: 24px 0;
  }

  .note {
    @include desktop-body-medium-font;
    margin-bottom: 8px;
  }

  .noteError {
    color: $purple;
  }

  .error {
    @include desktop-body-medium-font;
    color: $purple;
    height: 20px;
    margin-top: 8px;
  }

  .actions {
    margin-top: 36px;
    display: flex;
    justify-content: center;
    gap: 24px;
  }
}
