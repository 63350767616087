.formContainer {
  width: 100%;
  margin-top: 30px;
}

.formInlineFields {
  display: flex;
  gap: 20px;
}

.formButtons {
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
  gap: 15px;
}

.subpageContainer {
  width: 100%;
}

.subpageHeader {
  width: 100%;
  display: flex;
}

.subpageBackNavigationLink {
  display: flex;
  align-items: center;
}

.subpageBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 640px;
  padding: 30px;
  margin: 30px auto 0 auto;
}

.subpageTitle {
  text-align: left;
  width: 100%;
}

.container {
  padding: 30px 30px 30px 260px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
