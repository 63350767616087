@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.button {
  width: 150px;
  height: 48px;
}
.modalBtn {
  background-color: $color-red-front !important;

  &:hover {
    background-color: $red !important;
  }
}
.modalContent {
  color: $color-front;
}
