.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.title {
  font-size: 1.5em;
}
