.pagination {
  &-main-container {
    display: flex;
    flex-direction: column;
  }
  &-container {
    display: flex;
    align-self: flex-end;
    align-items: center;
  }
  &-items-text {
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
  }
  &-select {
    width: 100px;
  }
  &-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
