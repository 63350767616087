.container {
  ul {
    list-style-position: inside;
    padding-bottom: 8px;
    font-size: 14px;
    line-height: 20px;

    li {
      padding-left: 8px;
    }
  }

  .imageUploadContainer {
    max-width: 124px;
  }

  .imageContainer {
    display: flex;
    flex-direction: column;
    gap: 31px;

    button {
      margin-left: auto;
    }

    .image {
      max-height: 117px;
      min-height: 117px;
      max-width: 255px;
      object-fit: contain;
      object-position: left;
    }
  }
}
