@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.containerEmpty {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;

  .containerEmptyText {
    @include desktop-title-medium-font;
    color: $black-16;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .item {
    display: flex;
    width: 60%;
    flex-direction: row;
    gap: 24px;
  }
}
