@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.modalContainer {
  color: $color-front;

  .domainInfo {
    margin-bottom: 10px;
    font-size: 14px;

    .domainTitle {
      font-weight: 500;
      margin-right: 4px;
    }
  }

  .notification {
    box-sizing: border-box;
    width: 100%;
  }
}
