.results-table {
  border-collapse: collapse;
  width: 100%;
  overflow-x: scroll;

  &__header,
  &__cell {
    border: 1px solid #000;
    text-align: left;
    padding: 8px;
    font-size: 14px;
  }
  &__header {
    font-weight: 600;
  }
}
