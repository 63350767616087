@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.modal {
  width: 630px;

  .form {
    :global(.sz-modal-buttons) {
      margin-top: 36px;
    }

    .title {
      @include desktop-title-medium-font;
      margin: 24px 0;
    }
  }
}
