@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.project-request-approval-status-modal {
  width: 608px;

  .project-request-approval-status-form {
    &__fields {
      margin: 36px 0;
      display: flex;
      flex-direction: column;
      gap: 28px;
      max-height: 400px;
      overflow-y: auto;
    }

    &__fields-row {
      display: flex;
      justify-content: space-between;
      flex-direction: row;

      .sz-field-label {
        width: 100px;
        min-width: 100px;
      }
    }

    &__fields-array-error {
      @include desktop-caption-font;
      color: $purple;
      height: 16px;
    }

    .checkbox__input {
      min-width: 13px;
    }

    &__checkbox-label {
      @include desktop-body-medium-font;
    }

    &__checkbox-label-note {
      color: $black-38;
    }
  }
}
