@import '~quill/dist/quill.snow.css';

.Editor {
  .ql-toolbar.ql-snow {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .ql-container.ql-snow {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    p {
      font-size: 12px;
      line-height: 1.33;
      letter-spacing: 0.2px;
    }

    .ql-size-small {
      font-size: 10px;
    }

    .ql-size-large {
      font-size: 16px;
      line-height: 1.5;
    }

    .ql-size-huge {
      font-size: 20px;
    }

    strong {
      font-weight: 500;
    }
  }

  .ql-tooltip .ql-editing {
    top: -10px !important;
    left: calc(50% - 122px) !important;
  }

  .ql-tooltip {
    top: -10px !important;
    left: calc(50% - 150px) !important;
  }
}
