@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  display: flex;
  align-items: center;
  gap: 24px;

  .label {
    @include desktop-body-medium-font;
    width: 250px;
  }

  .weightInput {
    width: 100px;
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .orientationSelect {
    width: 100px;
  }
}
