@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  display: flex;
  flex-direction: column;
  padding-left: 16px;

  .firstRow {
    padding-top: 10px;
    padding-bottom: 24px;
  }

  .row,
  .longRow {
    display: flex;
    gap: 16px;

    .sectionTitle {
      @include desktop-title-font;
      color: $color-front;
    }
  }

  .row {
    & > * {
      width: 50%;
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 24px;
  }

  .invitationActions {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
