@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.fieldsList {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 8px $black-16;
  }

  .fieldsListItem {
    display: flex;
    align-items: center;
    width: 40%;
    gap: 24px;
  }

  .smallButton {
    :global(.button__content) {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  .icon {
    width: 16px;
    height: 16px;
  }

  .actions {
    margin-top: 8px;
  }
}
