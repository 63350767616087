@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  @include desktop-headline-font;
}
