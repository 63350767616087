@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.loaderWrapper {
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 32px;

  .nav {
    max-width: 1200px;
    width: 100%;
    height: 32px;
  }

  .content {
    max-width: 960px;
    width: 100%;
  }

  .heading {
    @include desktop-headline-medium-font;
    color: $black-100;
    margin-bottom: 32px;
  }
}
