@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.formFieldTitle {
  @include desktop-body-medium-font;
  color: $color-front;
  margin-top: 4px;
  margin-bottom: 8px;
  display: block;
}

.shareLinkTitle {
  @include desktop-body-medium-font;
  color: $color-front;
  margin-top: 4px;
}

.inlineFormFields {
  display: flex;
  align-items: center;

  button {
    margin-left: 8px;
  }
}

.inlineNotification {
  width: 100%;
  margin: calc(24px - 16px) 0 24px 0; // 16px being input error label placeholder
}

.shareLinkWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  .sharableLink {
    @include desktop-body-font;
    color: $color-front;
  }
}
