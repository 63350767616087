.container {
  padding-bottom: 53px;

  .table {
    min-height: 0px !important;
  }

  .table ~ div {
    min-height: 216px;
  }

  .empty ~ div {
    border-bottom: 0px;
  }

  th:first-of-type,
  td:first-of-type {
    width: 56px;
  }

  td:last-of-type {
    display: flex;
    justify-content: flex-end;
  }

  :global {
    .cds--data-table-content {
      overflow-x: unset !important;
    }

    [class^='sz-dropdown-menu-module_menu-item-button'] {
      color: red !important;
    }

    [class^='sz-dropdown-menu-module_label'] {
      margin-top: 2px;
    }

    #dropdown-menu-container {
      padding-bottom: 4px;
    }

    .cds--pagination__left {
      opacity: 0;
    }
  }
}

.hoverActionIcon {
  * path {
    fill: red !important;
  }
}

th,
td {
  padding: 12px 8px 12px 8px !important;
  height: 56px;
}

/** 
  It's not possible to easily hide the table toolbar delimiter 
  via targetted selectors or props. So we hide via first/last child here.  
   **/
.toolbar > div {
  opacity: 0;
}

.toolbar > div:first-child {
  opacity: 1;
}

.toolbar > div:last-child {
  opacity: 1;
}

.hoverActionButton {
  z-index: 9999 !important;
  position: relative;
  svg {
    display: block;
    position: relative;
  }
}
