@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  max-width: 600px;
  margin: auto;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  color: $color-front;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    .headerTitle {
      @include desktop-title-medium-font;
      margin-bottom: 24px;
    }

    .subheaderTitle {
      @include desktop-title-font;
      padding-left: 16px;
      padding-bottom: 16px;
    }

    .subheaderActions {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  .jobRolesList {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 32px;
    padding-left: 16px;
  }

  .containerEmpty {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;

    .containerEmptyText {
      @include desktop-title-medium-font;
      color: $black-16;
    }
  }

  .tabsContainer {
    margin-left: 16px;
  }

  .tabsPanel {
    margin-top: 24px;
  }
}
