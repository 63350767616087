.skillsListItem {
  display: flex;
  align-items: center;
  gap: 24px;

  :global(.button__content) {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .weightInput {
    max-width: 70px;
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .includeInCalibrationSelect {
    max-width: 120px;
  }

  .icon {
    margin-top: -2px;
    width: 16px;
    height: 16px;
  }

  .fieldWrapper {
    width: 100%;
  }
}
