@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 32px;

  .nav {
    max-width: 1200px;
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
  }

  .content {
    max-width: 960px;
    width: 100%;

    .contentHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;

      .heading {
        @include desktop-headline-medium-font;
        color: $black-100;
      }

      .datesSection {
        display: flex;
        gap: 24px;

        .dateCard {
          .dateCardLabel {
            color: $black-38;
            @include desktop-caption-font;
            margin-right: 4px;
          }

          .dateCardValue {
            @include desktop-caption-font;
          }
        }
      }
    }
  }
}
