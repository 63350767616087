@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  padding: 8px;
  padding-left: 10px;
  margin-bottom: 8px;
  display: flex;
  gap: 8px;

  .label {
    @include desktop-body-font;
    color: $color-front-light;
  }

  .valueContainer {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .value {
    @include desktop-body-medium-font;
    color: $color-front;
  }

  [class^='react-tooltip'] {
    opacity: 1;
    background-color: $color-front;
  }
}
