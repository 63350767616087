@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.project-requests-view {
  padding: 32px 108px;
  display: flex;
  gap: 40px;
  flex-direction: column;
  height: calc(100vh - 80px);
  box-sizing: border-box;
  overflow: auto;

  .project-requests-view-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__text {
      @include desktop-headline-medium-font;
    }

    &__right {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    &__create-button {
      .button__content {
        gap: 4px;
        display: flex;
        align-items: center;
      }
    }
  }

  // ProjectRequestsList component
  .project-requests-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .project-requests-list-item {
      width: 100%;
      padding: 16px 24px;
      border-radius: 8px;
      box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.1);
      height: 80px;
      display: flex;
      align-items: center;
      flex-direction: row;

      &:hover {
        box-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.12);
      }

      &__left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        gap: 36px;
        width: calc(100% - 79px);
      }

      &__left-item {
        display: flex;
        align-items: center;
        gap: 12px;
        flex: 1;

        &_updated-at {
          max-width: 100px;
        }

        &_position-center {
          justify-content: center;
        }
      }

      &__company-logo-wrapper {
        display: flex;
        align-items: center;
        width: 160px;
      }

      &__right {
        display: flex;
        flex-direction: row;
        gap: 4px;
        width: 176px;
        justify-content: flex-end;
      }
    }

    .project-request-status-label {
      padding: 4px 8px;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      @include desktop-body-font;

      &_color_green {
        background-color: $green-10;
        color: $green;
      }

      &_color_purple {
        background-color: $purple-10;
        color: $purple;
      }

      &_color_gray {
        background-color: $lightgrey;
        color: $grey;
      }

      &_color_blue {
        background-color: $blue-10;
        color: $blue;
      }
    }
  }

  .project-requests-list-loader {
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}
