@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  .hero {
    margin-bottom: 32px;
  }

  .info {
    min-width: 50%;
  }

  .item {
    width: 100%;
    margin-bottom: 16px;
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  .ampersand {
    margin-right: 8px;
    padding-bottom: 8px;
    display: flex;
    align-items: flex-end;
    @include desktop-body-font;
  }

  .input {
    width: 259px;
    margin-right: 12px;
  }
}
