@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .secondFieldsRow,
  .firstFieldsRow {
    display: flex;
    gap: 24px;
    align-items: center;
  }

  .fieldWrapper,
  .jobFamilyField {
    max-width: 220px;
    width: 100%;
  }

  .jobFamilyField {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .useCustomWeightsCheckboxWrapper {
    min-width: 150px;
  }

  .customWeightLabel {
    min-width: 150px;
    @include desktop-body-medium-font;

    color: $color-front;
  }

  .customWeightLabel_error {
    color: $purple;
  }

  .infoIcon {
    margin-top: -3px;
  }

  .skillsWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .softSkillsError {
    @include desktop-caption-font;
    color: $color-red-front;
    position: absolute;
    bottom: 40px;
  }
  .actions {
    display: flex;
    gap: 8px;
  }
}
