@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.file-item {
  display: flex;
  align-items: center;
  gap: 4px;

  &__file-icon {
    height: 16px;
    width: 16px;
  }

  &__file-name {
    text-decoration: underline;
    @include desktop-caption-medium-font;
  }
}
