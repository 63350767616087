.edit-hr-manager-employee-id-modal {
  width: 608px;

  &__loader-wrapper {
    height: 309px;
    display: flex;
    justify-content: center;
  }

  .edit-hr-manager-employee-id-form {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: space-between;

    &__fields {
      margin: 36px 0;
    }

    &__flex-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 24px;
    }

    &__actions {
      gap: 15px;
    }
  }
}
