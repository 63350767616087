@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.projectsFilterModal {
  .actions {
    padding-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .actionsRight {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }
}
