@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.container {
  padding: 40px 40px 32px 40px;

  .heading {
    color: $color-front;
    @include desktop-headline-medium-font;
    line-height: 28px;
    padding: 16px 0;
  }

  .table {
    [class^='table-module_toolbarContainer'] {
      height: 60px;
      box-sizing: border-box;
    }

    :global {
      .cds--data-table {
        th:nth-child(1) {
          width: 35%;
        }

        th:nth-child(2) {
          width: 20%;
        }

        th:nth-child(3),
        th:nth-child(4),
        th:nth-child(5) {
          width: 15%;
        }
      }
    }

    .tagCellWrapper {
      padding: 0 8px;
    }

    .archivedTag {
      button {
        background-color: $color-disabled-back;
        color: $color-front-light;
        padding-left: 8px;
        padding-right: 8px;
        @include desktop-body-font;
      }
    }

    .toolbarContainer {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      align-items: flex-end;
      height: 44px;

      .searchForm {
        width: 280px;
      }
    }
  }
}
