@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.logout-button {
  .button__content {
    display: flex;
    align-items: center;
    gap: 4px;
    color: $black-100;
    font-weight: $medium-bold;
  }

  &__icon {
    width: 16px;
    height: 16px;
  }
}
