@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.notification-modal {
  width: 397px;
  height: 244px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__check-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $green-10;
    height: 64px;
    width: 64px;
    border-radius: 50%;
    margin-bottom: 16px;
  }

  &__message {
    @include desktop-body-medium-font;
  }

  &__check-icon {
    path {
      fill: $green;
    }
  }

  &__button-wrapper {
    margin-top: 36px;
  }
}
