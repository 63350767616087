@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.itemsCount {
  display: flex;
  flex-direction: row-reverse;
  gap: 4px;

  padding: 8px 16px;
  border: 2px solid $color-blue-back;
  border-radius: 40px;

  .items {
    @include desktop-body-medium-font;
    color: $color-front;
  }

  .count {
    @include desktop-body-medium-font;
    color: $color-blue-front;
  }
}
