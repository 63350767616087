@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.project-request-create {
  padding: 32px 108px;

  .button {
    .button__content {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  &__back-button {
    .project-request-create__back-button-icon {
      margin-top: -3px;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
  }

  .project-request-create-form {
    max-width: 600px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 40px;

    &__title {
      @include desktop-headline-medium-font;
    }

    &__fields-row {
      display: flex;
      gap: 24px;
      align-items: center;
    }

    &__company-select {
      max-width: 204px;
    }

    &__project-name-input {
      max-width: 308px;
    }

    &__save-button-icon {
      transform: rotate(180deg);

      path {
        fill: $white;
      }
    }
  }

  .contacts-sub-form {
    &__add-contact-button {
      margin-top: 16px;
    }

    &__add-icon {
      width: 16px;
      height: 16px;

      path {
        fill: $black;
      }
    }

    .contacts-sub-from-row {
      display: flex;
      gap: 24px;
      align-items: center;

      &__role-select {
        max-width: 150px;
      }

      &__employee-select {
        max-width: 362px;
      }

      &__delete-button {
        &:hover {
          .project-request-create-form__delete-icon {
            width: 16px;
            height: 16px;

            path {
              fill: $green;
            }
          }
        }
      }

      &__delete-icon {
        width: 16px;
        height: 16px;

        path {
          fill: $black-54;
        }
      }
    }
  }
}
