@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.body {
  @include desktop-body-font;
}

.successBody {
  margin-bottom: 72px;
}
.errorBodyFirstPara {
  margin-bottom: 16px;
}

.errorBodySecondPara {
  margin-bottom: 34px;
}
