@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.project-request-approval-modal {
  .project-request-approval-form {
    &__fields {
      margin: 36px 0;
      display: flex;
      flex-direction: column;
      gap: 28px;
      max-height: 390px;
      overflow-y: auto;
    }

    &__fields-array-error {
      @include desktop-caption-font;
      color: $purple;
      height: 16px;
    }

    &__checkbox-label {
      @include desktop-body-medium-font;
    }

    &__checkbox-label-note {
      color: $black-38;
    }
  }
}
