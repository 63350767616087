.candidate-usage,
.skills-validated,
.users {
  display: flex;
  flex-direction: column;
  &__description {
    margin: 20px 0px;

    b {
      font-weight: bold;
    }

    &__notes {
      padding: 10px 20px;
      li {
        margin: 5px 0px;
      }
    }
  }
  &__error-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
  }
  &__input-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  &__date-container {
    display: flex;
    flex-direction: row;
  }
  &__button-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  &__input-item {
    flex-basis: 25%;
  }
  &__pages {
    margin: 20px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-table {
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    &__row-container {
      display: flex;
    }
    &__description {
      margin: 20px 0px;

      &__notes {
        padding: 10px 20px;
        li {
          margin: 5px 0px;
        }
      }
    }
    display: flex;
  }
  &-table-cell {
    &__item {
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-basis: 25%;
      border: 1px solid black;
      padding: 10px;
      flex-wrap: wrap;
      &[is-heading~='true'] {
        font-weight: 700;
      }
    }
  }
}
