@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.instruction_container {
  margin: 24px 0px;
}
.info_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.info_title {
  @include desktop-body-medium-font;
}

.info_paragraph {
  @include desktop-body-font;
}

.inline_notification {
  width: 452px;
}

.link {
  margin-left: 4px;
  margin-right: 4px;
  color: $color-interactive;
  text-decoration: underline;
}
