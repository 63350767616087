@import '@spotted-zebra-uk/ui-components/dist/sz-theme/index.scss';

.add-project-request-document-modal {
  width: 454px;

  .add-project-request-document-modal-form {
    margin-top: 36px;

    .sz-modal-buttons {
      margin-top: 36px;
    }

    .sz-file-upload-input__value {
      color: $black-38;
    }
  }
}
